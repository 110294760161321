import React, { useEffect, useState } from 'react'
import StatusBar from '../components/statusBar';
import { useTranslation } from 'react-i18next';
import ShuffleList from '../functions/shuffleList';
import VocabularyButtons from './vocabularyButtons';

function VocabularyBox(props) {

    const [correctlyAnswered, setCorrectlyAnswered] = useState(false);
    const [incorrectlyAnswered, setIncorrectlyAnswered] = useState(false);
    const [lessonComplete, setLessonComplete] = useState(false);
    const [currentQuestionPosition, setCurrentQuestionPosition] = useState(0);
    const [shuffledQuestionSet, setShuffledQuestionSet] = useState([]);
    const [score, setScore] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        setShuffledQuestionSet(ShuffleList(props.data.lessonByUuid.questionSet));
    }, [props.data.lessonByUuid.questionSet]);

    function onSelectCorrectAnswer() {
        setCorrectlyAnswered(true);
        setIncorrectlyAnswered(false);
        setScore(score + 1);
        props.onSelectCorrectAnswer();
    }

    function onSelectIncorrectAnswer() {
        setIncorrectlyAnswered(true);
        props.onSelectIncorrectAnswer();
    }

    function goToNextWord() {
        if (lessonComplete) {
            props.onUserFinished();
        }
        else {
            setCurrentQuestionPosition(currentQuestionPosition + 1);
            setCorrectlyAnswered(false);
        }

    }

    if (shuffledQuestionSet.length === 0) {
        return null;
    }

    return <div className="divQuestionBox">
        <h2>{shuffledQuestionSet[currentQuestionPosition].faroeseWord}</h2>
        <p>{t('What is')} <span className="highlight">{shuffledQuestionSet[currentQuestionPosition].faroeseWord}</span> {t('in Icelandic')}?</p>

        <VocabularyButtons
            suggestions={shuffledQuestionSet[currentQuestionPosition].suggestions}
            onSelectCorrectAnswer={onSelectCorrectAnswer}
            onSelectIncorrectAnswer={onSelectIncorrectAnswer}
            correctWord={shuffledQuestionSet[currentQuestionPosition].icelandicWord}
            correctAnswerHasBeenChosen={correctlyAnswered}>
        </VocabularyButtons>

        {incorrectlyAnswered ? <div className='tryAgainBox'>{t('Try again')}</div> : ""}

        {score !== 0 ?
            <StatusBar
                progress={score}
                itemCount={shuffledQuestionSet.length}
                onLessonComplete={() => setLessonComplete(true)}>
            </StatusBar> : ""
        }
        {correctlyAnswered ? <button className='margin-top-m btn standard'
            onClick={goToNextWord}>{lessonComplete ? t('See score') : t('Next word')}</button> : ""}
    </div>

}

export default VocabularyBox;