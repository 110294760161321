import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';

const LESSONS_QUERY = gql`
    query {
      allLessons {
        lessonUuid
        lessonName
      }
    }
    `;

function LessonChooserExcludeOne(props) {
  const { loading, error, data } = useQuery(LESSONS_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const initialMemo = [];
  const lessonListItems = data.allLessons.reduce((memo, lesson) => {
    if(lesson.lessonUuid !== props.excludingId) {
      memo.push(
      <a key={lesson.lessonUuid} href={lesson.lessonUuid}>
        <li>{lesson.lessonName}</li>
      </a>);
    }
    return memo;
    
  }, initialMemo);

  return <ul>{ lessonListItems }</ul>
}

export default LessonChooserExcludeOne;