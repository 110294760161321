import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import React from 'react';

const LESSONS_QUERY = gql`
    query {
      allLessons {
        lessonUuid
        lessonName
      }
    }
    `;

function LessonChooser(props) {
    const { loading, error, data } = useQuery(LESSONS_QUERY);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const lessonListItems = data.allLessons.map(({ lessonUuid, lessonName }) => (
        <a key={lessonUuid} href={lessonUuid}>{lessonName}</a>
    ));

    return <div>{lessonListItems}</div>
}

export default LessonChooser;