import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import CongratulationsScreen from './congratulationsScreen';
import VocabularyBox from './vocabularyBox';
import Introduction from './introduction';

function Lesson(props) {
    const [userIsFinished, setUserIsFinished] = useState(false);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [wrongAnswers, setWrongAnswers] = useState(0);
    const [userHasAcceptedLessonText, setUserHasAcceptedLessonText] = useState(false);

    const LESSONS_QUERY = gql`
        query {
            lessonByUuid(uuid: \"${props.currentLessonId}\") {
                id
                lessonName
                lessonLessonText
                lessonHasLessonText
                questionSet {
                    id
                    faroeseWord
                    icelandicWord
                    suggestions {
                        id
                        suggestionSuggestion
                    }
                }
            }
        }
        `;

    const { loading, error, data } = useQuery(LESSONS_QUERY);

    const { t } = useTranslation();

    if (loading) return <p>{t('Loading') + '...'}</p>;
    if (error) return <p>{t('Error')} :(</p>;

    const handleUserIsFinished = () => {
        setUserIsFinished(true);
    };

    const onSelectCorrectAnswer = () => {
        setCorrectAnswers(correctAnswers + 1);
    };

    const onSelectIncorrectAnswer = () => {
        setWrongAnswers(wrongAnswers + 1);
    };

    const handleLessonTextUserAccepts = () => {
        setUserHasAcceptedLessonText(true);
    };

    const resetScore = () => {
        setCorrectAnswers(0);
        setWrongAnswers(0);
        setUserIsFinished(false);
    };

    return (
        <div>
            {!data.lessonByUuid.lessonHasLessonText || userHasAcceptedLessonText ? (
                userIsFinished ?
                (
                    <CongratulationsScreen
                        lessonId={props.currentLessonId}
                        finalScore={{ wrongAnswers, correctAnswers }}
                        onReset={resetScore}
                    />
                ) : (
                    <VocabularyBox
                        data={data}
                        lessonId={props.currentLessonId}
                        onUserFinished={handleUserIsFinished}
                        onSelectCorrectAnswer={onSelectCorrectAnswer}
                        onSelectIncorrectAnswer={onSelectIncorrectAnswer}
                    />
                )
            ) : (
                    <Introduction introductionText={data.lessonByUuid.lessonLessonText} onClick={handleLessonTextUserAccepts} />
            )
            }
        </div>
    );
}

export default Lesson;