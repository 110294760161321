import CorrectAnswerFinder from './correctAnswerFinder';
import React, { useState, useEffect } from 'react';
import ShuffleList from '../functions/shuffleList';

function VocabularyButtons(props) {

    const [correctAnswerId, setCorrectAnswerId] = useState('');
    const [shuffledSuggestions, setShuffledSuggestions] = useState([]);

    useEffect(() => {
        setShuffledSuggestions(ShuffleList(props.suggestions));
    }, [props.suggestions]);

    function onSelectAnswer(selectedAnswerId, selectedAnswer, correctAnswer) {
        const answerFinder = new CorrectAnswerFinder();

        if(props.correctAnswerHasBeenChosen) {
            return;
        }

        if (answerFinder.isCorrect(selectedAnswer, correctAnswer)) {
            setCorrectAnswerId(selectedAnswerId);

            props.onSelectCorrectAnswer();
        }
        else {
            props.onSelectIncorrectAnswer();
        }
    }

    return shuffledSuggestions.map(suggestion => {
        return (
            <div key={suggestion.id}>
                <button className={correctAnswerId === suggestion.id ? 'btn btnSuggestion success' : 'btn btnSuggestion'}
                    onClick={
                        (e) => {
                            onSelectAnswer(suggestion.id, suggestion.suggestionSuggestion, props.correctWord)
                        }
                    }>
                    {suggestion.suggestionSuggestion}
                </button>
            </div>
        );
    })
}

export default VocabularyButtons;