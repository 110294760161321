import React, { useState } from 'react';
import LessonChooser from '../components/lessonChooser';
import Sidebar from '../components/sidebar';
import Lesson from '../components/lesson';


function Homepage(props) {

    const constructorLessonId = props.match.params.currentLessonId || 'LOP2f';
    const [currentLessonId] = useState(constructorLessonId);
    const title = "isord.fo";

    return (
        <div className="MainTemplate">
            <Sidebar>
                <h2>{ title }</h2>
                <LessonChooser />
            </Sidebar>
            <Lesson currentLessonId={currentLessonId} />
        </div>
    );
}

export default Homepage;