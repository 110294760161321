import { useTranslation } from 'react-i18next';

function Introduction(props) {

    const { t } = useTranslation();

    return (
        <div className="divQuestionBox divIntroductionBox">
            <div>{props.introductionText}</div>
            <button onClick={props.onClick} >OK</button>
        </div>
    );
}

export default Introduction;