function StatusBar(props) {

    const progress = Math.ceil(props.progress * 100 / props.itemCount);

    if(progress >= 100) {
        props.onLessonComplete();
    }

    return (
        <div className="statusBarBackground margin-top-s">
            <div className="statusBarBackground progress" style={{width: progress + "%"}}></div>
        </div>
    );
}

export default StatusBar;