import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import '../IsordTheme.css';

const Sidebar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const closeSidebar = () => {
        setIsOpen(false);
    }

    const openSidebar = () => {
        setIsOpen(true);
    }

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (event.target.closest('aside') === null) {
                closeSidebar();
            }
        };

        if (isOpen) {
            document.addEventListener('click', handleDocumentClick);
        }

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [isOpen]);

    return (
        <aside>
            <div className={isOpen ? "sidebar-open" : "display-none"}>
                <div>{children}</div>
                <button className="sidebar_close_button"
                    onClick={closeSidebar}><FontAwesomeIcon icon={faXmark} /></button>
            </div>
            <div className={isOpen ? "display-none" : ""}
                onClick={openSidebar}>
                {<div className="sidebar_open_button"><FontAwesomeIcon icon={faBars} /></div>}
            </div>
        </aside>
    );
};

export default Sidebar;