import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LessonChooserExcludeOne from './lessonChooserExcludeOne';

function CongratulationsScreen(props) {
    const { t } = useTranslation();

    return <div>
        <h2>{t('Congratulations!')}</h2>
        <h3>{t('You answered everything correctly!')}</h3>
        <h3>{t('Correct answers') + ": " + props.finalScore.correctAnswers}</h3>
        <h3>{t('Incorrect answers') + ": " + props.finalScore.wrongAnswers}</h3>
        <div className="margin-top-s">
            <button className="btn standard" onClick={props.onReset}>{t('Try again')}</button>
        </div>
        <h3>{t('Continue learning!')}</h3>
        <LessonChooserExcludeOne excludingId={props.lessonId}></LessonChooserExcludeOne>
    </div>;
}

export default CongratulationsScreen;