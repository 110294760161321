import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

const resources = {
    fo: {
        translation: {
            'Guess what the word': 'Gita hvat orðið',
            'is in Icelandic': 'er á íslendskum',
            'Next word': 'Næsta orð',
            'Congratulations!': 'Til lukku!',
            'You answered everything correctly!': 'Til lukku! Tú svaraði øllum rætt!',
            'Try again': 'Royn aftur',
            'Correct answers': 'Røtt svar',
            'Incorrect answers': 'Skeiv svar',
            'Loading': "Innlesur",
            'Continue learning!': 'Hald á fram at læra!',
            'See score': 'Síggj úrslit',
            'Error': 'Feilur',
            'What is': 'Hvat er',
            'in Icelandic': 'á íslendskum'
        }
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'fo',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;