import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Homepage from './pages/homepage';
import Backend from './pages/backend';

function App() {
    return (
        <Router>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/:currentLessonId" component={Homepage} />
            <Route exact path="/backend" component={Backend} />
        </Router>
    );
}

export default App;